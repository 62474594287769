import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { appLogo } from "../assets/images";
// import { hotelIcon, foodIcon, travelIcon } from "../assets/icons";
import Carousel from "../components/organisms/Carousel";
import "./services.css";

const AboutUs = () => {
    return (
        <div className="container-parent mb-4">
        <Container>
        <Helmet>
            <title>Best Cab Service in Gurgaon</title>
            <meta name="description" content="Looking for a trip, looking for a tour, Salasar Tour & Travels Calculator Page, schedule and time table, get best booking fare, reservation" />
            <link rel="canonical" href="https://www.salasartravellers.in/" />
            <meta property="og:site_name" content="salasartravellers.in" />
            <meta charset="utf-8" />
            <meta description="Car Rental, Taxi Service, Bus Hire" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://www.salasartravellers.in/" />
            <meta name="twitter:description" content="Salasar Tours &amp; Travels, Chomun, Rajasthan, India. 1 like. Salasar Tours &amp; Travels are in Domestic Tours, International Tours, Visa, Air Tickets, Railway Tickets, Currency Exchanger, Fund Transfer" />
            <meta property="og:title" content="Salasar tour and travels" />
            <meta property="og:image:alt" content="Salasar Tours and Travels " />
            <meta property="og:image" content={appLogo} />
            <meta name="twitter:title" content="Salasar Tours & Travels" />
            <meta property="og:description" content="Salasar Travels - We offer Salasar Tours in Chandni Chowk, Delhi, Delhi. Find here details about our company including contact, Salasar tour and travles, salasar tour & travels, salasar travellers" />
            <meta data-react-helmet="true" name="description" content="Book Gurgaon Salasar Volvo AC  Bus Tickets online booking 
              and get upto 500 Rs OFF at salasartravellers.in. Volvo AC bus available for Gurgaon to Salasar Travel with Seat Availability &amp; Fares." />
            <meta data-react-helmet="true" name="keywords"
              content="tempu traveller, taxi service for outstation, tempu traveller on rent, tour and travel gurgaon,
              taxi on rent, tempu on rent, tempu traveller on rent in gurgaon, travelling agency, ncr cab service, ncr taxi service,
              24 hours taxi service, taxi service in gurgaon, taxi service in gurugram, best cab service in gurgaon, 
              best cab service in delhi, delhi, gurugram, gurgaon, ncr, delhi ncr, cheap cab rates, family plan cab service,
              cab service, tempu traveller, cab service, taxi service, travel Companies, popular Travel Agents, 
              best Travel Agents, salasar tour and travel in Delhi, salasar tour and travel in gurgaon, 
              salasar tour and travel ratings, reviews of salasar tour and travel, 
              salasar tour and travel phone numbers, 
              contact salasar tour and travels" />
        </Helmet>
            {/* <div className="text text-white"> */}
            <div className="text">
                <h2>ABOUT US</h2>
            </div>
            <div className="rowitems">
                {/* <Row className="justify-content-md-center">
                    <Col xs lg="4">
                        <div className="container-box mb-4">
                            <div className="container-image">
                                <img src={hotelIcon} alt="Hotel Services" />
                            </div>
                            <h4>Hotel Services</h4>
                            <p>Check-in/out</p>
                        </div>
                    </Col>
                    <Col md="auto" lg="4">
                        <div className="container-box mb-4">
                            <div className="container-image">
                                <img src={foodIcon} alt="Food Services" />
                            </div>
                            <h4>Food Services</h4>
                            <p>Catering</p>
                        </div>
                    </Col>
                    <Col xs lg="4">
                        <div className="container-box mb-4">
                            <div className="container-image">
                                <img src={travelIcon} alt="Travel Services" />
                            </div>
                            <h4>Travel Services</h4>
                            <p>Pick-up/drop</p>
                        </div>
                    </Col>
                </Row> */}
                <Carousel />
            </div>
        </Container >
        </div>
    )
}

export default AboutUs;