const getTime = (hours, minutes) => {
// 0 1... 23
let hour = hours > 12 ? hours - 12 : hours;
hour = hour === 0 ? 12 : hour;
let minutesData = minutes;
let timeZone = hours < 12 ? 'AM' : 'PM';
minutesData = minutesData === 0 ? '00' : minutesData;

const result = `${hour} : ${minutesData} - ${timeZone}`;
return result;
}

export default getTime;