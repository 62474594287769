import { Carousel as RBCarousel } from 'react-bootstrap';
import { innovaCrystaCar, jaipurPic, xcentCar } from '../../assets/images';

const Carousel = () => {
  return (
    <RBCarousel data-bs-theme="dark" className='p-10'>
      <RBCarousel.Item>
        <img
          className="d-block w-100 h-500 bg-white"
          src={xcentCar}
          alt="First slide"
        />
        <RBCarousel.Caption>
          {/* <h5>First slide label</h5>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
        </RBCarousel.Caption>
      </RBCarousel.Item>
      <RBCarousel.Item>
        <img
          className="d-block w-100 h-500 bg-white"
          src={jaipurPic}
          alt="Second slide"
        />
        <RBCarousel.Caption>
          {/* <h5>Second slide label</h5>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
        </RBCarousel.Caption>
      </RBCarousel.Item>
      <RBCarousel.Item>
        <img
          className="d-block w-100 h-500 bg-white"
          src={innovaCrystaCar}
          alt="Third slide"
        />
        <RBCarousel.Caption>
          {/* <h5>Third slide label</h5>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p> */}
        </RBCarousel.Caption>
      </RBCarousel.Item>
    </RBCarousel>
  );
}

export default Carousel;
