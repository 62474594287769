import { Container, Form, InputGroup, Row, Col, Button } from "react-bootstrap";
import { EMPTY_STRING, RATES, VEHICLE_TYPES } from "./layouts.constants";
import { Formik } from "formik";
import * as Yup from "yup";
import { Helmet } from "react-helmet";
import "./services.css";
import { useState } from "react";
import { appLogo, qrCode } from "../assets/images";

const Calculator = () => {
    const initialValues = {
        distance: EMPTY_STRING,
        vehicleType: EMPTY_STRING,
        // {
        //     label: "Select vehicle Type",
        //     value: EMPTY_STRING
        // },
        fare: 0,
    }
    const [totalFare, setTotalFare] = useState(0);

    const validationSchema = Yup.object().shape({
        distance: Yup.string()
            .required("*Distance is required"),
        vehicleType: Yup.string()
            .required("*Vehicle Type is required"),
    });

    const getFare = (distance, selectedVehicle) => {
        let totalFare = 0;
        if (!isNaN(distance)) {
            const vehicle = RATES[`${selectedVehicle}`];
            console.log("vehicle ===> ", vehicle)
            const rate = vehicle.rate;
            const overLimitRate = vehicle.overLimitRate;
            if (distance && Number(distance) <= 250) {
                totalFare = rate * 250;
            } else {
                const compulsoryFare = rate * 250;
                const remainingKilometers = Number(distance) - 250;
                const remainingFare = overLimitRate * remainingKilometers;
                totalFare = compulsoryFare + remainingFare;
            }
        }
        setTotalFare(totalFare.toString());
    }

    return (
        <div className="container-parent mb-4">
        <Container>
        <Helmet>
            <title>Tempu traveller on rent - Gurgaon</title>
            <meta name="description" content="Looking for a trip, looking for a tour, Salasar Tour & Travels Calculator Page, schedule and time table, get best booking fare, reservation" />
            <link rel="canonical" href="https://www.salasartravellers.in/" />
            <meta property="og:site_name" content="salasartravellers.in" />
            <meta charset="utf-8" />
            <meta description="Car Rental, Taxi Service, Bus Hire" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://www.salasartravellers.in/" />
            <meta name="twitter:description" content="Salasar Tours &amp; Travels, Chomun, Rajasthan, India. 1 like. Salasar Tours &amp; Travels are in Domestic Tours, International Tours, Visa, Air Tickets, Railway Tickets, Currency Exchanger, Fund Transfer" />
            <meta property="og:title" content="Salasar tour and travels" />
            <meta property="og:image:alt" content="Salasar Tours and Travels " />
            <meta property="og:image" content={appLogo} />
            <meta name="twitter:title" content="Salasar Tours & Travels" />
            <meta property="og:description" content="Salasar Travels - We offer Salasar Tours in Chandni Chowk, Delhi, Delhi. Find here details about our company including contact, Salasar tour and travles, salasar tour & travels, salasar travellers" />
            <meta data-react-helmet="true" name="description" content="Book Gurgaon Salasar Volvo AC  Bus Tickets online booking 
              and get upto 500 Rs OFF at salasartravellers.in. Volvo AC bus available for Gurgaon to Salasar Travel with Seat Availability &amp; Fares." />
            <meta data-react-helmet="true" name="keywords"
              content="tempu traveller, taxi service for outstation, tempu traveller on rent, tour and travel gurgaon,
              taxi on rent, tempu on rent, tempu traveller on rent in gurgaon, travelling agency, ncr cab service, ncr taxi service,
              24 hours taxi service, taxi service in gurgaon, taxi service in gurugram, best cab service in gurgaon, 
              best cab service in delhi, delhi, gurugram, gurgaon, ncr, delhi ncr, cheap cab rates, family plan cab service,
              cab service, tempu traveller, cab service, taxi service, travel Companies, popular Travel Agents, 
              best Travel Agents, salasar tour and travel in Delhi, salasar tour and travel in gurgaon, 
              salasar tour and travel ratings, reviews of salasar tour and travel, 
              salasar tour and travel phone numbers, 
              contact salasar tour and travels" />
        </Helmet>
            {/* <div className="text text-white"> */}
            <div className="text">
                <h2>LET'S CALCULATE YOUR FARE</h2>
            </div>
            <div className="rowitems">
                <Row className="justify-content-md-center">
                    <Col xs lg="6">
                        <div className="container-box mb-4">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                className='book-trip-form'
                                onSubmit={(values, { setSubmitting, resetForm }) => {
                                    getFare(values.distance, values.vehicleType).toString()
                                }}
                            >{({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                            }) =>
                                <Form onSubmit={handleSubmit}>
                                    <h5>On demand rates could be changed</h5>
                                    <Form.Group className='mb-3'>
                                        <InputGroup>
                                            <InputGroup.Text className="mb-1">Distance</InputGroup.Text>
                                            <Form.Control
                                                className={touched.distance && errors.distance ? "error mb-1" : 'mb-1'}
                                                name='distance'
                                                size='md'
                                                type='text'
                                                placeholder="Distance"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.distance}
                                            />
                                            <InputGroup.Text className="mb-1">Kms</InputGroup.Text>
                                        </InputGroup>
                                        <InputGroup>
                                            <InputGroup.Text className="mb-1">Vehicle</InputGroup.Text>
                                            <Form.Control
                                                as="select"
                                                name="vehicleType"
                                                size="md"
                                                type="text"
                                                placeholder="Vehicle Type"
                                                className={touched.vehicleType && errors.vehicleType ? "error mb-1" : 'mb-1'}
                                                value={values.vehicleType}
                                                onChange={handleChange}>
                                                onBlur={handleBlur}
                                                {VEHICLE_TYPES.map(vt => (
                                                    <option key={vt.value} value={vt.value}>{vt.label}</option>
                                                ))}
                                            </Form.Control>
                                        </InputGroup>
                                        <Button className="mtb-1" type="submit">Calculate Fare</Button>
                                        <InputGroup>
                                            <InputGroup.Text className="mt-3">₹</InputGroup.Text>
                                            <Form.Control
                                                name="fare"
                                                className={"mt-3"}
                                                size='md'
                                                type='number'
                                                placeholder='Fare'
                                                value={totalFare}
                                                disabled
                                            />
                                            <InputGroup.Text className="mt-3">.00</InputGroup.Text>
                                        </InputGroup>
                                        <h5 className="mtb-2">Including all charges*</h5>
                                    </Form.Group>
                                </Form>
                                }
                            </Formik>
                        </div>
                    </Col>
                    <Col md="auto" lg="6">
                        <div className="container-box container-box-qr mb-4">
                            <h4>Here you can pay!</h4>
                            <div className="container-image">
                                <img className="qr-image" src={qrCode} alt="QR code" />
                            </div>
                            <h5>For more details please contact us:</h5>
                            <h5>+91 98120 47003</h5>
                        </div>
                    </Col>
                </Row>
            </div>
        </Container >
        </div>
    )
}

export default Calculator;