import Footer from "./components/organisms/Footer";
import NavBar from "./components/organisms/Navbar";
import AboutUs from "./layouts/About";
import Calculator from "./layouts/Calculator";
import Home from "./layouts/Home";
import Locations from "./layouts/Locations";
import Packages from "./layouts/Packages";
import Services from "./layouts/Services";

const MainPage = () => {
    return (
        <>
            <NavBar />
            <Home />
            <Calculator />
            <AboutUs />
            <Services />
            <Locations />
            <Packages />
            <Footer />
        </>
    )
}

export default MainPage;