import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
// import { hydrate, render } from "react-dom";
import ReactGA from "react-ga4";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.min.css";

// const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(document.getElementById('root'));
// ReactGA.initialize("G-3X7H8SPC3B");
// if (rootElement.hasChildNodes()) {
//   hydrate(<React.StrictMode>
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
//   </React.StrictMode>, rootElement);
// } else {
//   render(<React.StrictMode>
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
//   </React.StrictMode>, rootElement);
// }

// start
const rootElement = document.getElementById("root");
ReactGA.initialize("G-3X7H8SPC3B");
if (rootElement.hasChildNodes()) {
  root.render(<React.StrictMode>
        <BrowserRouter>
         <App />
        </BrowserRouter>
     </React.StrictMode>, rootElement, rootElement.firstElementChild);
} else {
  root.render(<React.StrictMode>
        <BrowserRouter>
          <App />
        </BrowserRouter>
     </React.StrictMode>, rootElement);
}
// end

// root.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
//   </React.StrictMode>
// );

const SendAnalytics = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics);
