import {
  Container,
  Nav,
  Navbar,
  Image,
} from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { SocialIcon } from 'react-social-icons';
import { appLogo } from '../../assets/images';

const NavBar = () => {
  return (
    <>
      <Helmet>
            <title>Home - Salasar Tour and Travels</title>
            <meta name="description" content="Looking for a trip, looking for a tour, Salasar Tour & Travels Calculator Page, schedule and time table, get best booking fare, reservation" />
            <link rel="canonical" href="https://www.salasartravellers.in/" />
            <meta property="og:site_name" content="salasartravellers.in" />
            <meta charset="utf-8" />
            <meta description="Car Rental, Taxi Service, Bus Hire" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://www.salasartravellers.in/" />
            <meta name="twitter:description" content="Salasar Tours &amp; Travels, Chomun, Rajasthan, India. 1 like. Salasar Tours &amp; Travels are in Domestic Tours, International Tours, Visa, Air Tickets, Railway Tickets, Currency Exchanger, Fund Transfer"></meta>
            <meta property="og:title" content="Salasar tour and travels" />
            <meta property="og:image:alt" content="Salasar Tours and Travels "></meta>
            <meta property="og:image" content={appLogo}></meta>
            <meta name="twitter:title" content="Salasar Tours & Travels"></meta>
            <meta property="og:description" content="Salasar Travels - We offer Salasar Tours in Chandni Chowk, Delhi, Delhi. Find here details about our company including contact, Salasar tour and travles, salasar tour & travels, salasar travellers" />
            <meta data-react-helmet="true" name="description" content="Book Gurgaon Salasar Volvo AC  Bus Tickets online booking 
              and get upto 500 Rs OFF at salasartravellers.in. Volvo AC bus available for Gurgaon to Salasar Travel with Seat Availability &amp; Fares." />
            <meta data-react-helmet="true" name="keywords"
              content="Salasar Tour & Travels Jind Ho, Reviews, Contact number, Phone number, Address, Map, 
              Salasar Tour & Travels Jind, Ratings, Directions, Official website link, Working hours, Services" />
        </Helmet>
      <Navbar className='navbar-custom' bg='bg-secondary' expand="lg">
        <Container>
          <Navbar.Brand className='navbar-brand-custom' href="#home">
            <Image className='w-80 border-radius-26' src={appLogo} alt="brand-image"/>
            <h3 className='tagline-custom'>Salasar Tour & Travels</h3>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="#home">Home</Nav.Link>
              <Nav.Link href="#link">About</Nav.Link>
              <Nav.Link href="#link">Contact</Nav.Link>
              <Nav.Link href="#link">Locations</Nav.Link>
              <Nav.Link href="#link">Register</Nav.Link>
              {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown> */}
            </Nav>
            <Nav.Link className="justify-content-end" href="#login">
            {/* <div className="col-md-3"> */}
                                <ul className="social-network social-circle">
                                    <li><SocialIcon className='social-media-icons' url="https://facebook.com" /> </li>
                                    <li><SocialIcon className='social-media-icons' url="https://instagram.com" />   </li>
                                    <li><SocialIcon className='social-media-icons' url="https://gmail.com" /></li>
                                </ul>
                            {/* </div> */}
            </Nav.Link>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavBar;
