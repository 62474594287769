import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import "./locations.css"
import { firstImage, thirdImage, fourthImage, xcentCar, innovaCrystaCar, innovaCar, appLogo } from "../assets/images";

const Packages = () => {
    return (
        <div className="container-parent mb-4">
            <Container>
            <Helmet>
            <title>Packages - Salasar Tour and Travels</title>
            <meta name="description" content="Looking for a trip, looking for a tour, Salasar Tour & Travels Calculator Page, schedule and time table, get best booking fare, reservation" />
            <link rel="canonical" href="https://www.salasartravellers.in/" />
            <meta property="og:site_name" content="salasartravellers.in" />
            <meta charset="utf-8" />
            <meta description="Car Rental, Taxi Service, Bus Hire" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://www.salasartravellers.in/" />
            <meta name="twitter:description" content="Salasar Tours &amp; Travels, Chomun, Rajasthan, India. 1 like. Salasar Tours &amp; Travels are in Domestic Tours, International Tours, Visa, Air Tickets, Railway Tickets, Currency Exchanger, Fund Transfer" />
            <meta property="og:title" content="Salasar tour and travels" />
            <meta property="og:image:alt" content="Salasar Tours and Travels " />
            <meta property="og:image" content={appLogo} />
            <meta name="twitter:title" content="Salasar Tours & Travels" />
            <meta property="og:description" content="Salasar Travels - We offer Salasar Tours in Chandni Chowk, Delhi, Delhi. Find here details about our company including contact, Salasar tour and travles, salasar tour & travels, salasar travellers" />
            <meta data-react-helmet="true" name="description" content="Book Gurgaon Salasar Volvo AC  Bus Tickets online booking 
              and get upto 500 Rs OFF at salasartravellers.in. Volvo AC bus available for Gurgaon to Salasar Travel with Seat Availability &amp; Fares." />
            <meta name="keywords" content="best cab service in gurgaon, best taxi service in gurgaon, online booking, best cab service in ncr, cheap cab rates, cab for hill station, 
              cab service book online, tempu traveller, taxi service for outstation, tempu traveller on rent, 
              tour and travels gurgaon cab service, cab on cheap rate, taxi on cheap rate, best cab service in gurgaon, best
              taxi service in gurgaon, family plan cab service, family plan taxi service, 
              taxi on rent, tempu on rent, tempu traveller on rent in gurgaon, travelling agency, ncr cab service, ncr taxi service,
              24 hours taxi service, taxi service in gurgaon, taxi service in gurugram, best cab service in gurgaon, 
              best cab service in delhi, delhi, gurugram, gurgaon, ncr, delhi ncr, cheap cab rates, family plan cab service,
              cab service, tempu traveller, cab service, taxi service, travel Companies, popular Travel Agents, 
              best Travel Agents, salasar tour and travel in Delhi, salasar tour and travel in gurgaon, 
              salasar tour and travel ratings, reviews of salasar tour and travel, 
              salasar tour and travel phone numbers, 
              contact salasar tour and travels" />
                <meta property="og:url" content="https://www.salasartravellers.in" />
                <meta property="og:type" content="website" />
            </Helmet>
                {/* <div className="text text-white"> */}
                <div className="text">
                    <h2>OUR BEST PLANS</h2>
                </div>
                <div className="rowitems">
                    <Row className="justify-content-md-center mb-4">
                        <Col xs lg="4" className="p-10">
                            <div className="col-content mb-4">
                                <div>
                                    <img className="price-image" src={fourthImage} alt="swift car price" />
                                </div>
                            </div>
                            <div className="price-label">
                                <h5>Swift Dezire</h5>
                                <p>Price: 10 INR PER KM</p>
                                <p>Max Dist: 250Kms</p>
                                <p>Extra: 14 INR PER KM</p>
                            </div>
                        </Col>
                        <Col md="auto" lg="4" className="p-10">
                            <div className="col-content mb-4">
                                <div>
                                    <img className="price-image" src={xcentCar} alt="xcent car pic" />
                                </div>
                            </div>
                            <div className="price-label">
                                <h5>Xcent</h5>
                                <p>Price: 10 INR PER KM</p>
                                <p>Max Dist: 250Kms</p>
                                <p>Extra: 14 INR PER KM</p>
                            </div>
                        </Col>
                        <Col md="auto" lg="4" className="p-10">
                            <div className="col-content mb-4">
                                <div>
                                    <img className="price-image" src={thirdImage} alt="ertiga car price" />
                                </div>
                            </div>
                            <div className="price-label">
                                <h5>Ertiga</h5>
                                <p>Price: 14 INR PER KM</p>
                                <p>Max Dist: 250Kms</p>
                                <p>Extra: 18 INR PER KM</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col xs lg="4" className="p-10">
                            <div className="col-content mb-4">
                                <div>
                                    <img className="price-image" src={innovaCar} alt="innova car price" />
                                </div>
                            </div>
                            <div className="price-label">
                                <h5>Innova</h5>
                                <p>Price: 18 INR PER KM</p>
                                <p>Max Dist: 250Kms</p>
                                <p>Extra: 20 INR PER KM</p>
                            </div>
                        </Col>
                        <Col md="auto" lg="4" className="p-10">
                            <div className="col-content mb-4">
                                <div>
                                    <img className="price-image" src={innovaCrystaCar} alt="crysta car price" />
                                </div>
                            </div>
                            <div className="price-label">
                                <h5>Crysta</h5>
                                <p>Price: 20 INR PER KM</p>
                                <p>Max Dist: 250Kms</p>
                                <p>Extra: 26 INR PER KM</p>
                            </div>
                        </Col>
                        <Col xs lg="4" className="p-10">
                            <div className="col-content mb-4">
                                <div>
                                    <img className="price-image" src={firstImage} alt="tempu price" />
                                </div>
                            </div>
                            <div className="price-label">
                                <h5>Tempu</h5>
                                <p>Price: 28 INR PER KM</p>
                                <p>Max Dist: 250Kms</p>
                                <p>Extra: 33 INR PER KM</p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
            {/* <Container>
                <section className="newsletter">
                    <div className="newstext text-white">
                        <h2>Weekly Updates</h2>
                        <p className="text-white">Subscribe to get offers and latest<br />updates every week!</p>
                    </div>
                    <div className="send">
                        <form action="">
                            <input type="email" name="emailid" placeholder="E-mail" required />
                            <input type="submit" value="Subscribe" />
                        </form>
                    </div>
                </section>
            </Container> */}
            {/* <Container>
                <section className="newsletter">
                    <div className="newstext">
                        <h2>QR Payment</h2>
                        <p>For payment use this<br />QR Code</p>
                    </div>
                    <div className="send">
                        <form action="">
                            <img className="qr-code-img" src={qrCode} alt='qr-code' />
                        </form>
                    </div>
                </section>
            </Container> */}
        </div>
    )
}

export default Packages;
