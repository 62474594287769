import React from "react";
import { Helmet } from "react-helmet";
import { SocialIcon } from 'react-social-icons'
import { appLogo } from "../../assets/images";

const Footer = () => {
    return (
        <>
            <Helmet>
            <title>Footer - Salasar Tour and Travels</title>
            <meta name="description" content="Looking for a trip, looking for a tour, Salasar Tour & Travels Calculator Page, schedule and time table, get best booking fare, reservation" />
            <link rel="canonical" href="https://www.salasartravellers.in/" />
            <meta property="og:site_name" content="salasartravellers.in" />
            <meta charset="utf-8" />
            <meta description="Car Rental, Taxi Service, Bus Hire" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://www.salasartravellers.in/" />
            <meta name="twitter:description" content="Salasar Tours &amp; Travels, Chomun, Rajasthan, India. 1 like. Salasar Tours &amp; Travels are in Domestic Tours, International Tours, Visa, Air Tickets, Railway Tickets, Currency Exchanger, Fund Transfer"></meta>
            <meta property="og:title" content="Salasar Tour and Travels" />
            <meta property="og:image:alt" content="Salasar Tour and Travels"></meta>
            <meta name="twitter:title" content="Salasar Tours & Travels"></meta>
            <meta property="og:description" content="Salasar Travels - We offer Salasar Tours in Chandni Chowk, Delhi, Delhi. Find here details about our company including contact, Salasar tour and travles, salasar tour & travels, salasar travellers" />
            <meta data-react-helmet="true" name="description" content="Book Gurgaon Salasar Volvo AC  Bus Tickets online booking 
              and get upto 500 Rs OFF at salasartravellers.in. Volvo AC bus available for Gurgaon to Salasar Travel with Seat Availability &amp; Fares." />
            
            <meta property="og:image" content={appLogo} />
            <meta property="og:url" content="https://www.salasartravellers.in" />
            <meta property="og:type" content="website" />
            <meta data-react-helmet="true" name="keywords"
              content="Gurgaon Salasar Volvo AC Bus Tickets, Gurgaon Salasar Volvo AC Bus Tickets Booking, Gurgaon Salasar Volvo AC AC Bus Ticket Online, 
                Gurgaon Salasar Volvo AC AC Bus Booking, tour packages from delhi, delhi tour packages, holiday package from delhi, Online Ticket Booking, 
                Ticket Booking, Ticket Booking Offers, Book Bus Tickets, Online Ticket Booking Offers, Bus Ticket Discount Offers, Salasar Travels,
                Gurgaon to Salasar bus, Gurgaon to Salasar sanitised bus, Gurgaon to Salasar Covid-19 Safe bus, Gurgaon to Salasar bus tickets,
                Gurgaon to Salasar sanitised bus tickets, Book Gurgaon to Salasar bus tickets, Gurgaon to Salasar Bus Tickets Online, 
                Cheapest bus tickets for Gurgaon to Salasar, Baba Toor & Travels, Salasar (Sujangarh) Churu, " />
            </Helmet>
            <footer className="mb-auto mainfooter" role="contentinfo">
                <div className="footer-middle">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3 col-sm-6">
                                <div className="footer-pad">
                                    <h4>Gurugram Branch Office</h4>
                                    <ul className="list-unstyled">
                                        <li>Office No. 143</li>
                                        <li>Plot No. 50</li>
                                        <li>Near Park Hospital</li>
                                        <li>Sector 47</li>
                                        <li>Mobile No. +91 98120 47003</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="footer-pad">
                                    <h4>Barwala Head Office</h4>
                                    <ul className="list-unstyled">
                                        <li>Office No. 100</li>
                                        <li>Kakad Market</li>
                                        <li>Hanshi Road</li>
                                        <li>Barwala</li>
                                        <li>Mobile No. +91 98120 47003</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="footer-pad">
                                    <h4>Gurugram Branch Office </h4>
                                    <ul className="list-unstyled">
                                        <li>Plot No. 455</li>
                                        <li>Office No. 671</li>
                                        <li>Near Keshar Mall</li>
                                        <li>Sector 55</li>
                                        <li>Mobile No. +91 98120 47003</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <h4> Follow Us </h4>
                                <ul className="social-network social-circle">
                                    <li><SocialIcon url="https://facebook.com" /> </li>
                                    <li><SocialIcon url="https://instagram.com" />   </li>
                                    <li><SocialIcon url="https://gmail.com" /></li>
                                </ul>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 copy">
                                {/* <p className="text-center"> © Copyright 2021 - Company Name.  All rights reserved. </p> */}
                                <p className="text-center"> Salasar Tour and Travels - Make a trip plan - Great plans are coming. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;
