import Form from 'react-bootstrap/Form';
import { Button, Card, Col, Row, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import emailjs from '@emailjs/browser';
import { EMPTY_STRING, REGEX } from './layouts.constants';
import './home.css';
import { useEffect, useRef, useState } from 'react';
import DatePicker from "react-datepicker";
import { Helmet } from 'react-helmet';
import "react-datepicker/dist/react-datepicker.css";
import getTime from '../helpers/getTime';
import CustomAlert from '../components/molecules/CustomAlert';
import { appBackgroundImage, appLogo } from '../assets/images';
import {
    GoogleMap,
    Marker,
    Autocomplete,
    DirectionsRenderer,
    LoadScript,
} from "@react-google-maps/api";
import debounce from "lodash/debounce";

const center = {
    lat: 28.4595, // 7.8731,
    lng: 77.0266, //80.7718,
};

const { PHONE } = REGEX;
const EMAILJS_SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
const EMAILJS_TEMPLATE_ID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
const EMAILJS_USER_ID = process.env.REACT_APP_EMAILJS_USER_ID;
const THANKS_EMAILJS_TEMPLATE = process.env.REACT_APP_THANKS_EMAIL_TEMPLATE;

const Home = () => {
    const form = useRef();
    const [buttonText, setButtonText] = useState('Book Your Trip');
    const [buttonVariant, setButtonVariant] = useState('primary');
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [shouldShowAlert, setShouldShowAlert] = useState(false);
    const [alertVariant, setAlertvariant] = useState("info");
    const [alertHeading, setAlertHeading] = useState("Hey, nice to see you");
    const [alertContent, setAlertContent] = useState("Aww yeah, you successfully submitted the request. Our customer executive will coordinate with you soon.");
    const [totalDistance, setTotalDistance] = useState(0)
    const [totalTime, setTotalTime] = useState(0)
    const [mapCenter, setMapCenter] = useState(center);

    useEffect(() => {
        setMapCenter(center);
    }, [])

    const initialValues = {
        name: EMPTY_STRING,
        email: EMPTY_STRING,
        phone: EMPTY_STRING,
        pickup: EMPTY_STRING,
        destination: EMPTY_STRING,
        travelDate: new Date(),
        tripType: false,
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .min(2, "*Names must have at least 2 characters")
            .max(100, "*Names can't be longer than 100 characters")
            .required("*Name is required"),
        email: Yup.string()
            .email("*Must be a valid email address")
            .max(100, "*Email must be less than 100 characters")
            .required("*Email is required"),
        phone: Yup.string()
            .matches(PHONE, "*Phone number is not valid")
            .required("*Phone number required"),
        pickup: Yup.string()
            .min(2, "*Pickup Location must have at least 2 characters")
            .max(100, "*Pickup Location can't be longer than 100 characters")
            .required("*Pickup Location is required"),
        destination: Yup.string()
            .min(2, "*Destination Location must have at least 2 characters")
            .max(100, "*Destination Location can't be longer than 100 characters")
            .required("*Destination Location is required"),
        travelDate: Yup.string()
            .required("*Travel Date is required"),
    });

    const [map, setMap] = useState(null);
    const [directionsResponse, setDirectionsResponse] = useState(null);

    const originRef = useRef();
    const destinationRef = useRef();

    async function calculateRoute() {
        setDirectionsResponse(null)
        if (originRef.current.value === EMPTY_STRING || destinationRef.current.value === EMPTY_STRING) {
            return;
        }
        const directionsService = new window.google.maps.DirectionsService();
        const results = await directionsService.route({
            origin: originRef.current.value,
            destination: destinationRef.current.value,
            waypoints: [],
            travelMode: window.google.maps.TravelMode.DRIVING,
            unitSystem: window.google.maps.UnitSystem.METRIC,
        });
        setTotalDistance(results.routes[0].legs[0].distance.text);
        setTotalTime(results.routes[0].legs[0].duration.text);
        setDirectionsResponse(results);
    }

    function clearRoute() {
        if (originRef.current && destinationRef.current) {
            originRef.current.value = EMPTY_STRING;
            destinationRef.current.value = EMPTY_STRING;
        }
        setDirectionsResponse(null);
        setMapCenter(center);
    }

    useEffect(() => {
        clearRoute()
    }, [])

    const letsLoadDirection = debounce(() => calculateRoute(), 1000)
    return (
        <div className="container-parent mb-4">
            <Helmet>
                <title>Salasar Tour and Travels</title>
                <meta name="description" content="Salasar Tour & Travels Home Page" />
                <link rel="canonical" href="https://www.salasartravellers.in/" />
                <meta property="og:site_name" content="salasartravellers.in" />
                <meta charset="utf-8" />
                <meta description="Car Rental, Taxi Service, Bus Hire" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.salasartravellers.in/" />
                <meta property="og:title" content="Salasar tour and travels" />
                <meta property="og:image:alt" content="Salasar Tours and Travels " />
                <meta property="og:image" content={appLogo} />
                <meta property="og:description" content="Salasar tour and travles, salasar tour & travels, salasar travellers" />
                <meta data-react-helmet="true" name="description" content="Book Gurgaon Salasar Volvo AC  Bus Tickets online booking 
              and get upto 500 Rs OFF at salasartravellers.in. Volvo AC bus available for Gurgaon to Salasar Travel with Seat Availability &amp; Fares." />
                <meta name="keywords" content="best cab services in gurgaon, best online cab booking, online taxi booking, cab service book online, tempu traveller, taxi service for outstation, tempu traveller on rent, tour and travel gurgaon,
              taxi on rent, tempu on rent, tempu traveller on rent in gurgaon, travelling agency, ncr cab service, ncr taxi service,
              24 hours taxi service, taxi service in gurgaon, taxi service in gurugram, best cab service in gurgaon, 
              best cab service in delhi, delhi, gurugram, gurgaon, ncr, delhi ncr, cheap cab rates, family plan cab service,
              cab service, tempu traveller, cab service, taxi service, travel Companies, popular Travel Agents, 
              best Travel Agents, salasar tour and travel in Delhi, salasar tour and travel in gurgaon, 
              salasar tour and travel ratings, reviews of salasar tour and travel, 
              salasar tour and travel phone numbers, 
              contact salasar tour and travels" />
            </Helmet>
            <div className=''>
                {/* <video src={bgvid} type='video/mp4' autoPlay muted loop></video> */}
                <div style={{ backgroundRepeat: 'round', backgroundSize: 'auto', backgroundImage: `url(${process.env.PUBLIC_URL + `${appBackgroundImage}`})` }} className='content' id='home'>
                    <div className='title'>
                        <h1>SALASAR TOUR & TRAVELS</h1>
                        <p>Plan your trip with us and travel in the India with the most affordable packages</p>
                    </div>
                    {/* <div className='title parent-book-trip-form'> */}
                    <Row className='pb-1 ps-2 pe-2'>
                        {/* <div className='child-book-trip-form'> */}
                        <Col lg={5}>
                            {shouldShowAlert ? <CustomAlert content={alertContent} variant={alertVariant} heading={alertHeading} />
                                : <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    className='book-trip-form'
                                    onSubmit={(values, { setSubmitting, resetForm }) => {
                                        setSubmitting(true);
                                        setButtonVariant('info')
                                        setButtonText('Sending Request...')
                                        setTimeout(() => {
                                            const templateParams = {
                                                to_name: "Salasar Travels",
                                                from_name: values.email,
                                                message: `Hi here are my details please check and reply as soons as possible:
                                            Name: ${values.name}
                                            Email: ${values.email}
                                            Phone: ${values.phone}
                                            Pick Up: ${originRef && originRef.current ? originRef.current.value : values.pickup}
                                            Destination: ${destinationRef && destinationRef.current ? destinationRef.current.value : values.destination}
                                            Trip Date: ${values.travelDate.getDate()}/${values.travelDate.getMonth()}/${values.travelDate.getFullYear()}
                                            Trip Time: ${getTime(selectedDate.getHours(), selectedDate.getMinutes())}
                                            Total Time: ${totalTime}
                                            Total Distance: ${totalDistance}
                                            Is two way: ${values.tripType}`
                                            };

                                            const touristEmailParams = {
                                                to_name: values.name,
                                                reply_to: values.email,
                                                from_name: "Support (support@salasartravellers.in)",
                                                message: "Hi, Thank you for requesting the service on Salasar Travellers Web App. Our executive will contact you soon. For any query you can reach out us at email: support@salasartravellers.in or call: +91 98120 47003"
                                            }

                                            emailjs.send(EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, templateParams, EMAILJS_USER_ID)
                                                .then((response) => {
                                                    if (response.status === 200) {
                                                        setButtonVariant('success')
                                                        setButtonText('Request sent successfully')

                                                        setTimeout(() => {
                                                            setButtonText('Book Your Trip');
                                                            setButtonVariant('primary')
                                                            resetForm();
                                                            setShouldShowAlert(true)
                                                            setTimeout(() => {
                                                                setShouldShowAlert(false)
                                                            }, 10000);
                                                            setSubmitting(false);
                                                        }, 1000)
                                                        emailjs.send(EMAILJS_SERVICE_ID, THANKS_EMAILJS_TEMPLATE, touristEmailParams, EMAILJS_USER_ID)
                                                    } else {
                                                        setShouldShowAlert(true);
                                                        setAlertvariant("danger");
                                                        setAlertHeading("Sorry! We are unablw to process the request.");
                                                        setAlertContent("You can contact our support team.")
                                                        setTimeout(() => {
                                                            setShouldShowAlert(false)
                                                        }, 5000);
                                                        setSubmitting(false);
                                                    }
                                                }, (err) => {
                                                    console.log('FAILED...', err);
                                                });
                                        }, 500);
                                    }}
                                >{({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting
                                }) =>
                                    <Form className='book-trip-form-child' ref={form} onSubmit={handleSubmit}>
                                        <Form.Group className='mb-3'>
                                            <Form.Control
                                                className={touched.name && errors.name ? "error mb-1" : 'mb-1'}
                                                name='name'
                                                size='md'
                                                type='text'
                                                placeholder='Your Name'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.name}
                                            />
                                        </Form.Group>
                                        <Form.Group className='mb-3'>
                                            <Form.Control
                                                name='email'
                                                className={touched.email && errors.email ? "error mb-1" : 'mb-1'}
                                                size='md'
                                                type='email'
                                                placeholder='Your Email'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.email}
                                            />
                                        </Form.Group>
                                        <Form.Group className='mb-3'>
                                            <Form.Control
                                                name='phone'
                                                className={touched.phone && errors.phone ? "error mb-1" : 'mb-1'}
                                                size='md'
                                                type='number'
                                                placeholder='Your Phone Number'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.phone}
                                            />
                                        </Form.Group>
                                        <LoadScript id="script-loader" googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API} libraries={["places"]}>
                                            <Autocomplete>
                                                <Form.Group className='mb-3'>
                                                    <Form.Control
                                                        name='pickup'
                                                        className={touched.pickup && errors.pickup ? "error mb-1" : 'mb-1'}
                                                        size='md'
                                                        type='text'
                                                        placeholder='Your Pickup Location'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={originRef.current ? originRef.current.value : EMPTY_STRING} // {values.pickup}
                                                        ref={originRef}
                                                    />
                                                </Form.Group>
                                            </Autocomplete>
                                            <Autocomplete>
                                                <Form.Group className='mb-3'>
                                                    <Form.Control
                                                        name='destination'
                                                        className={touched.destination && errors.destination ? "error mb-1" : 'mb-1'}
                                                        size='md'
                                                        type='text'
                                                        placeholder='Your Destination Location'
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            letsLoadDirection()
                                                        }}
                                                        onBlur={handleBlur}
                                                        value={destinationRef.current ? destinationRef.current.value : EMPTY_STRING} // {values.destination}
                                                        ref={destinationRef}
                                                    />
                                                </Form.Group>
                                            </Autocomplete>
                                        </LoadScript>
                                        <Form.Group className='mb-3'>
                                            <DatePicker
                                                dateFormat="Pp"
                                                showTimeSelect
                                                selected={selectedDate}
                                                onChange={setSelectedDate}
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Check // prettier-ignore
                                                name="tripType"
                                                type="switch"
                                                id="custom-switch"
                                                className='trip-toggle d-flex justify-content-center'
                                                label={values.tripType ? <h4>Two way</h4> : <h4>One way</h4>}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.tripType}
                                            />
                                        </Form.Group>
                                        <Form.Group className='mb-3 d-flex'>
                                            <Button
                                                variant={buttonVariant}
                                                className='mt-1 me-auto'
                                                type='submit'
                                                disabled={isSubmitting}
                                            >
                                                {Boolean(isSubmitting) &&
                                                    <Spinner
                                                        as="span"
                                                        animation="grow"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                }
                                                {buttonText}
                                            </Button>
                                            <Button
                                                variant="danger"
                                                className='mt-1 ms-auto'
                                                disabled={isSubmitting}
                                                onClick={() => clearRoute()}
                                            >
                                                Clear Map
                                            </Button>
                                        </Form.Group>
                                    </Form>
                                    }
                                </Formik>}
                        </Col>
                        {/* <div className='display-contents'> */}
                        <Col lg={2}>
                            <Card className='h-400 journey-details-card'>
                                <Row>
                                    <Col>
                                        <i className="fa fa-car fa-solid"></i>
                                        <h6><b>Distance:</b></h6>
                                        <h6>{totalDistance}</h6>

                                    </Col>
                                    <Col>
                                        <i className="fa fa-solid fa-clock-o"></i>
                                        <h6><b>Time    :</b></h6>
                                        <h6>{totalTime}</h6>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        {/* <div className='display-contents'>  */}
                        <Col lg={5}>
                            {/* {isLoaded &&  */}
                            <LoadScript id="script-loader" googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API} libraries={["places"]}>
                                <GoogleMap
                                    defaultCenter={center}
                                    center={mapCenter}
                                    zoom={5}
                                    mapContainerStyle={{ width: "100%", height: "400px", borderRadius: "10px" }}
                                    options={{
                                        zoomControl: false,
                                        streetViewControl: true,
                                        mapTypeControl: false,
                                        fullscreenControl: false,
                                    }}
                                    bootstrapURLKeys={{
                                        key: process.env.REACT_APP_GOOGLE_MAP_API,
                                    }}
                                    onLoad={(map) => setMap(map)}
                                >
                                    <Marker position={mapCenter} />
                                    {directionsResponse && (
                                        <DirectionsRenderer directions={directionsResponse} />
                                    )}
                                </GoogleMap>
                            </LoadScript>
                            {/* } */}
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default Home;
