const EMPTY_STRING = '';

const VEHICLE_TYPES = [
    {
        label: "Select Vehicle Type",
        value: ""
    },
    {
        label: "Dezire",
        value: "dezire",
    },
    {
        label: "Etios",
        value: "etios"
    },
    {
        label: "Xcent",
        value: "xcent"
    },
    {
        label: "Ertiga",
        value: "ertiga"
    },
    {
        label: "Innova",
        value: "innova"
    },
    {
        label: "Crysta",
        value: "crysta"
    },
    {
        label: "Bus",
        value: "bus"
    },
    {
        label: "Tempu Traveller",
        value: "tempu_traveller"
    }];

const RATES = {
    dezire: {
        rate: 14,
        overLimitRate: 14,
    },
    etios: {
        rate: 14,
        overLimitRate: 14,
    },
    xcent: {
        rate: 14,
        overLimitRate: 14,
    },
    ertiga: {
        rate: 19,
        overLimitRate: 19,
    },
    innova: {
        rate: 23,
        overLimitRate: 23,
    },
    crysta: {
        rate: 26,
        overLimitRate: 26,
    },
    tempu_traveller: {
        rate: 35,
        overLimitRate: 35,
    },
    bus: {
        rate: 100,
        overLimitRate: 100,
    }
}

const REGEX = {
    PHONE: /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/
}

export { EMPTY_STRING, RATES, REGEX, VEHICLE_TYPES };