import Alert from 'react-bootstrap/Alert';

const CustomAlert = ({ content, variant, heading }) => {
  return (
    <Alert variant={variant}>
      <Alert.Heading>{ heading }</Alert.Heading>
      <hr />
      <h6>
        { content }
      </h6>
    </Alert>
  );
}

export default CustomAlert;